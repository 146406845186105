import React, { useState, useEffect } from "react";
import projectServices from "../../API/projectServices";
import styles from "./Projects.module.css";
import noImg from "../../Assets/no_img.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Helper function to determine media type
const determineMediaType = (url) => {
    if (!url) return null; // Guard clause for undefined or null
    if (url.match(/\.(mp4|webm|ogg)$/i)) return "video";
    if (url.match(/\.(jpg|jpeg|png|gif)$/i)) return "image";
    return null;
};

export default function Projects() {
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchProjects = async (page) => {
        setLoading(true);
        setError(null);
        try {
            const { data } = await projectServices.getProjects(page);
            setProjects(data.projects || []);
        } catch (err) {
            setError("Failed to load projects. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const fetchTotalPages = async () => {
        try {
            const { data } = await projectServices.getPages();
            setTotalPages(data.pages || 1);
        } catch (err) {
            setError("Failed to fetch total pages. Please try again.");
        }
    };

    useEffect(() => {
        fetchTotalPages();
    }, []);

    useEffect(() => {
        fetchProjects(currentPage);
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className={styles.projects}>
            <Helmet>
                <title>Projects - CBA</title>
            </Helmet>
            <h1 className={styles.heading}>Projects</h1>
            {loading && <div className="loader"></div>}
            {error && <p className={styles.error}>{error}</p>}
            {!loading && !error && (
                <>
                    <div className={styles.projects__grid}>
                        {projects.length > 0 ? (
                            projects.map((project) => {
                                const mediaType = determineMediaType(project.firstMedia);
                                return (
                                    <div className={styles.project} key={project.id}>
                                        {mediaType === "video" ? (
                                            <video
                                                src={project.firstMedia}
                                                className={styles.project__media}
                                                muted
                                                loop
                                                autoPlay
                                            />
                                        ) : mediaType === "image" ? (
                                            <img
                                                src={project.firstMedia || noImg}
                                                alt={project.heading}
                                                className={styles.project__image}
                                            />
                                        ) : (
                                            <img
                                                src={noImg}
                                                alt="No media available"
                                                className={styles.project__image}
                                            />
                                        )}
                                        <h2 className={styles.project__title}>{project.heading}</h2>
                                        <p className={styles.project__description}>
                                            {project.description?.length > 440
                                                ? `${project.description.substring(0, 250)}...`
                                                : project.description}
                                        </p>
                                        <Link className="selectServiceBtn" to={`/project/${project.id}`}>
                                            Go to project
                                        </Link>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No projects found.</p>
                        )}
                    </div>
                    <div className={styles.pagination}>
                        <button
                            className={styles.clear__btn}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            &lt;
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i + 1}
                                className={`${styles.clear__btn} ${
                                    currentPage === i + 1 ? `${styles.active} selected` : ""
                                }`}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </button>
                        ))}
                        <button
                            className={styles.clear__btn}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            &gt;
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
