import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import projectServices from "../../../API/projectServices";
import styles from "./SelectedProject.module.css";
import { Helmet } from "react-helmet";

// Helper function to determine media type
const determineMediaType = (url) => {
    if (!url) return null;
    if (url.match(/\.(mp4|webm|ogg)$/i)) return "video";
    if (url.match(/\.(jpg|jpeg|png|gif)$/i)) return "image";
    return null;
};

export default function SelectedProject() {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchProject = async () => {
        setLoading(true);
        setError(null);
        try {
            const { data } = await projectServices.getProject(id);
            setProject(data.project || null);
        } catch (err) {
            setError("Failed to load project. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchProject();
        }
    }, [id]);

    if (loading) return <div className={`loader ${styles.loading}`}></div>;
    if (error) return <p className={styles.error}>{error}</p>;
    if (!project) return <p>No project found.</p>;

    return (
        <div className={styles.container}>
            <Helmet>
                <title>CBA - {project.heading}</title>
            </Helmet>
            <p className={styles.date}>
                {new Date(project.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                })}
            </p>
            <h1>{project.heading}</h1>
            <p className={styles.description}>{project.description}</p>
            {project.files?.length > 0 && (
                <div className={styles.mediaContainer}>
                    {project.files.map((file, index) => {
                        const mediaType = determineMediaType(file.url);
                        return mediaType === "video" ? (
                            <video
                                key={index}
                                src={file.url}
                                controls
                                className={styles.projectVideo}
                            />
                        ) : (
                            <img
                                key={index}
                                src={file.url}
                                alt={`Project Media ${index + 1}`}
                                className={styles.projectImage}
                            />
                        );
                    })}
                </div>
            )}
            {project.links?.length > 0 && (
                <div className={styles.links}>
                    <h2 className={styles.linksHeading}>Related Links</h2>
                    <ul>
                        {project.links.map((link, index) => (
                            <li key={index}>
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    {link}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
