import styles from './About.module.css'
import about_1 from '../../Assets/about_1.png'
import about_2 from '../../Assets/about_2.png'
import about_3 from '../../Assets/about_3.png'
import about_4 from '../../Assets/about_4.png'
import about_5 from '../../Assets/about_5.png'
import {Helmet} from "react-helmet";
import React from "react";

export default function About() {
  return (
      <div className={styles.about}>
          <Helmet>
              <title>About CBA</title>
          </Helmet>
          <section className={styles.first__section}>
              <img src={about_1} className={styles.first__img}/>
              <div className={styles.first__section__info}>
                  <h1>About Us</h1>
                  <p>
                      Welcome to the Coaching Association - an independent, non-profit professional body founded in
                      2007, dedicated to elevating the practice and standards of coaching.<br/><br/>
                      We strive to promote excellence in coaching, advance the coaching profession, and make a
                      sustainable impact on individuals, organisations, and society at large.
                  </p>
              </div>
          </section>
          <section className={styles.second__section}>
              <div className={styles.second__section__info}>
                  <h1>Our Mission</h1>
                  <p>
                      Our purpose is multi-fold and deeply embedded in the belief that quality coaching can profoundly
                      enhance personal and professional lives. Our activities are designed to:<br/> <br/>
                      <span className='p-span'>Develop and promote coaching practices:</span> we are committed to
                      developing cutting-edge coaching methodologies for different areas of life: personal,
                      professional, performance, educational, etc.<br/> <br/>
                      <span className='p-span'>Training for Youth:</span> we believe that preparing young people for
                      their personal and professional success is essential for our communities. We develop specialised
                      and tailored coaching experience for young people. We also invite young people to join us in our
                      mission as volunteers.<br/> <br/>
                      <span className='p-span'>Collaboration with businesses:</span> we partner with business
                      organisations to foster best practices in human resources development and introduce innovative
                      business practices that enhance corporate efficiency and effectiveness.
                  </p>
              </div>
              <img src={about_2} className={styles.second__img}/>
          </section>
          <section className={styles.third__section}>
              <img src={about_3} className={styles.third__img}/>
              <div className={styles.third__section__info}>
                  <p>
                      <span className='p-span'>Collaboration with businesses:</span> we partner with business
                      organisations to foster best practices in human resources development and introduce innovative
                      business practices that enhance corporate efficiency and effectiveness.<br/><br/>
                      <span className='p-span'>Educational partnerships:</span> our work extends to educational
                      institutions across all levels, from preschool to higher education, including private training
                      centers and NGOs. We collaborate to develop and implement effective methodologies and tools that
                      enhance learning outcomes and personal satisfaction among students. We have programs focused on
                      teacher’s development, as well teacher’s wellbeing.<br/><br/>
                      <span className='p-span'>Research and development:</span> we are actively involved in research and
                      development in the fields of coaching, psychology, education, sociology, and economics. These
                      efforts support our other activities and contribute to the theoretical and practical advancement
                      of the coaching field.<br/><br/>
                      <span className='p-span'>Promoting a positive image of coaching:</span> we strive to establish a
                      reputable and positive image of coaching methods, ensuring fair competition within the industry.
                  </p>
              </div>
          </section>
          <section className={styles.fourth__section}>
              <div className={styles.fourth__section__info}>
                  <h1>Our Core Values</h1>
                  <p>
                      Our foundation is built on four core values—Integrity, Excellence, Collaboration, and Respect.
                      These values guide our policies and are reflected in the high standards we set for the coaching
                      profession. We hold our coaches to these standards, emphasising their responsibility to uphold
                      these values and deliver exceptional coaching services.
                  </p>
              </div>
              <img src={about_4} className={styles.fourth__img}/>
          </section>
          <section className={styles.fifth__section}>
              <img src={about_5} className={styles.fifth__img}/>
              <div className={styles.fifth__section__info}>
                  <h1>Commitment To Growth</h1>
                  <p>
                      We believe in the potential within each individual for growth and transformation. <span className='p-span'>Our commitment
                      is to help people navigate their personal and professional journeys with meaning, joy, and
                      fulfillment through high-quality coaching programs.</span> We are dedicated to not just meeting, but
                      exceeding, the standards of coaching excellence, providing our members and their clients with
                      unparalleled support and resources.<br/><br/>
                      <span className='p-span'>Join us as a member or a coach at Coaching Association.</span> We inspire change and foster excellence
                      across the coaching community and beyond. Together, we can make a profound difference in the lives
                      of many, crafting a future that is rich with opportunity and achievement. Discover the power of
                      professional coaching with Coaching Association—where excellence meets purpose and possibility.
                  </p>
              </div>
          </section>
      </div>
  )
}