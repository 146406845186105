import React, { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import styles from './Header.module.css';
import logo from '../../Assets/logo.png';
import burger from '../../Assets/burger.svg';
import close from '../../Assets/close.svg';
import { Link, useNavigate } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null);

    const toggleMobileMenu = () => {
        if (!isMobileMenuOpen) {
            openMenu();
        } else {
            closeMenu();
        }
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const openMenu = () => {
        gsap.to(mobileMenuRef.current, {
            x: 0,
            duration: 0.5,
            ease: 'power3.out',
        });
    };

    const closeMenu = () => {
        gsap.to(mobileMenuRef.current, {
            x: '100%',
            duration: 0.5,
            ease: 'power3.in',
        });
    };

    useEffect(() => {
        gsap.set(mobileMenuRef.current, { x: '100%' }); // Initialize off-screen
    }, []);

    const goToContact = () => {
        if (window.location.pathname === '/') {
            const contactSection = document.getElementById('contact');
            if (contactSection) {
                const offset = -100;
                const topPosition = contactSection.getBoundingClientRect().top + window.pageYOffset + offset;

                window.scrollTo({
                    top: topPosition,
                    behavior: 'smooth',
                });
            }
        } else {
            localStorage.setItem('navigateToContact', true);
            navigate('/');
        }
    };

    return (
        <header className={styles.header}>
            <img onClick={() => navigate('/')} src={logo} className={styles.logo} alt="Logo" />

            {/* Desktop Navigation */}
            <nav className={styles.desktopNav}>
                <ul className={styles.navList}>
                    <li><Link className={styles.navLink} to="/">Home</Link></li>
                    <li><Link className={styles.navLink} to="/services">Our Services</Link></li>
                    <li><Link className={styles.navLink} to="/about">About Us</Link></li>
                    <li><Link className={styles.navLink} to="/projects">Projects</Link></li>
                    <li><button onClick={goToContact} className={`${styles.navLink} ${styles.contactButton}`}>Contact</button></li>
                </ul>
            </nav>

            {/* Mobile Menu */}
            <div className={styles.mobileMenuWrapper}>
                <button onClick={toggleMobileMenu} className={styles.burgerButton}>
                    <img src={burger} alt="Open menu" />
                </button>
                <nav ref={mobileMenuRef} className={styles.mobileNav}>
                    <button onClick={toggleMobileMenu} className={styles.closeButton}>
                        <img src={close} alt="Close menu" />
                    </button>
                    <ul className={styles.mobileNavList}>
                        <li><Link className={styles.navLink} to="/" onClick={toggleMobileMenu}>Home</Link></li>
                        <li><Link className={styles.navLink} to="/services" onClick={toggleMobileMenu}>Our Services</Link></li>
                        <li><Link className={styles.navLink} to="/about" onClick={toggleMobileMenu}>About Us</Link></li>
                        <li><Link className={styles.navLink} to="/projects" onClick={toggleMobileMenu}>Projects</Link></li>
                        <li><Link className={styles.navLink} to="/join/member" onClick={toggleMobileMenu}>Join as Member</Link></li>
                        <li><Link className={styles.navLink} to="/join/expert" onClick={toggleMobileMenu}>Join as Expert</Link></li>
                        <li><button onClick={() => { toggleMobileMenu(); goToContact(); }} className={`${styles.navLink} ${styles.contactButton}`}>Contact</button></li>

                    </ul>
                </nav>
            </div>

            <div className={styles.action__btns__wrapper}>
                <button onClick={() => { navigate('/join/member'); }} className="primary__btn">Join as a member</button>
                <button onClick={() => { navigate('/join/expert'); }} className="secondary__btn">Join as an expert</button>
            </div>
        </header>
    );
}
