import { useEffect, useState } from "react";
import mailingServices from "../../../API/mailingServices";
import styles from "./Mailings.module.css";

export default function Mailings() {
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        // Fetch email history on component mount
        const fetchHistory = async () => {
            try {
                const response = await mailingServices.getHistory();
                setEmails(response.data.mailingHistory); // Access `mailingHistory` from API response
            } catch (error) {
                console.error("Error fetching email history:", error);
            }
        };

        fetchHistory();
    }, []);

    return (
        <div className={styles.container}>
            {emails.map((email) => (
                <div key={email._id} className={styles.card}>
                    <h3 className={styles.name}>{email.name}</h3>
                    <p>
                        <span>Email:</span>
                        <span>{email.email}</span>
                    </p>
                    <p>
                        <span>Phone:</span>
                        <span>{email.phone || "N/A"}</span>
                    </p>
                    <p>
                        <span>Preferred Time:</span>
                        <span>{email.preferredTime}</span>
                    </p>
                    <p>
                        <span>Coaching Type:</span>
                        <span>{email.coachingType}</span>
                    </p>
                    <p>
                        <strong>Message:</strong>
                        <span>{email.message}</span>
                    </p>
                </div>
            ))}
        </div>
    );
}
