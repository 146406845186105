import { useEffect, useState } from "react";
import joinServices from "../../../API/joinServices";
import styles from "./Submissions.module.css";

export default function Submissions({ activeTab, setActiveTab, isMobile }) {
    const [members, setMembers] = useState([]);
    const [experts, setExperts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await joinServices.getAllRequests();
                const requests = response.data;
                setMembers(requests.filter((item) => item.type === "member"));
                setExperts(requests.filter((item) => item.type === "expert"));
            } catch (error) {
                console.error("Error fetching join requests:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <button
                    className={`${styles.tab} ${
                        activeTab === "members" ? styles.activeTab : ""
                    }`}
                    onClick={() => setActiveTab("members")}
                >
                    Members
                </button>
                <button
                    className={`${styles.tab} ${
                        activeTab === "experts" ? styles.activeTab : ""
                    }`}
                    onClick={() => setActiveTab("experts")}
                >
                    Experts
                </button>
            </div>
            <div className={styles.content}>
                {activeTab === "members" && (
                    <div className={styles.scrollable}>
                        {members.map((member) => (
                            <div key={member._id} className={styles.card}>
                                <h3 className={styles.name}>{member.fullName}</h3>
                                <p>
                                    <strong>Organisation name:</strong>{" "}
                                    {member.organizationName}
                                </p>
                                <p>
                                    <strong>Email:</strong> {member.email}
                                </p>
                                <p>
                                    <strong>Phone number:</strong>{" "}
                                    {member.phoneNumber}
                                </p>
                                <p>
                                    <strong>Organization Type:</strong>{" "}
                                    {member.organizationType}
                                </p>
                                <p>
                                    <strong>Reason for joining us:</strong>{" "}
                                    {member.reasonForJoining}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
                {activeTab === "experts" && (
                    <div className={styles.scrollable}>
                        {experts.map((expert) => (
                            <div key={expert._id} className={styles.card}>
                                <h3 className={styles.name}>{expert.fullName}</h3>
                                <p>
                                    <strong>Email:</strong> {expert.email}
                                </p>
                                <p>
                                    <strong>Phone number:</strong>{" "}
                                    {expert.phoneNumber}
                                </p>
                                <p>
                                    <strong>Areas of expertise:</strong>{" "}
                                    {expert.expertiseArea}
                                </p>
                                <p>
                                    <strong>Reason for joining us:</strong>{" "}
                                    {expert.reasonForJoining}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
