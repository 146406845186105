import http from "./http";

const adminServices = {
    login: async (username, password) => {
        try {
            console.log("Admin login attempt...");
            const response = await http.post("/admin/login", { username, password }, { withCredentials: true });
            console.log("Login successful:", response.data.message);
            return response.data;
        } catch (error) {
            console.error("Error during login:", error.response?.data || error.message);
            throw error;
        }
    },

    verify: async () => {
        try {
            console.log("Verifying admin session...");
            const response = await http.get("/admin/verify", { withCredentials: true });
            console.log("Session verified:", response.data.message);
            return response.data;
        } catch (error) {
            console.error("Session verification failed:", error.response?.data || error.message);
            throw error;
        }
    },

    // Fetch Facebook Pages
    fetchFacebookPages: async () => {
        try {
            console.log("Fetching Facebook pages...");
            const response = await http.get("/admin/facebook/pages", { withCredentials: true });
            console.log("Pages fetched:", response.data.pages);
            return response.data.pages;
        } catch (error) {
            console.error("Error fetching pages:", error.response?.data || error.message);
            throw error;
        }
    },


    changePassword: async (data) => {
        try {
            console.log("Changing password...");
            const response = await http.post("/admin/change-password", data, { withCredentials: true });
            console.log("Password changed:", response.data.message);
            return response.data;
        } catch (error) {
            console.error("Error changing password:", error.response?.data || error.message);
            throw error;
        }
    },

    changeUsername: async (data) => {
        try {
            console.log("Changing user...");
            const response = await http.post("/admin/change-user", data, { withCredentials: true });
            console.log("User changed:", response.data.message);
            return response.data;
        } catch (error) {
            console.error("Error changing user:", error.response?.data || error.message);
            throw error;
        }
    },

    fetchFacebookPagePosts: async (pageId, pageAccessToken) => {
        try {
            console.log(`Fetching posts for page ID: ${pageId} with Page Access Token.`);
            const response = await http.post(
                `/admin/facebook/posts/${pageId}`,
                { pageAccessToken }, // Pass the page access token in the body
                { withCredentials: true }
            );
            console.log(`Posts for page ID ${pageId} fetched:`, response.data.posts);
            return response.data.posts;
        } catch (error) {
            console.error("Error fetching posts:", error.response?.data || error.message);
            throw error;
        }
    },

    redirectToFacebookLogin: async () => {
        try {
            console.log("Fetching Facebook login URL...");
            const response = await http.get("/admin/facebook/login", { withCredentials: true });
            const facebookLoginUrl = response.data.url;
            console.log("Redirecting to:", facebookLoginUrl);
            window.location.href = facebookLoginUrl; // Redirect user to Facebook login
        } catch (error) {
            console.error("Error fetching Facebook login URL:", error.response?.data || error.message);
            throw error;
        }
    },

    downloadPostMedia: async (postId, pageAccessToken) => {
        try {
            console.log(`Downloading media for post ID: ${postId}`);
            const response = await http.post(
                "/admin/facebook/post/download",
                { postId, pageAccessToken },
                { withCredentials: true }
            );
            console.log("Post media downloaded:", response.data);
            return response.data; // Includes description, links, and media file paths
        } catch (error) {
            console.error("Error downloading post media:", error.response?.data || error.message);
            throw error;
        }
    },

    toggleMaintenance: async () => {
        return http.get("/admin/maintenance", { withCredentials: true });
    },

    getMaintenanceMode: async () => {
        return http.get("/admin/maintenance/status", { withCredentials: true });
    },
    setCookie: async (data) => {
        try {
            console.log(`Setting cookie with data: ${JSON.stringify(data)}`);
            const response = await http.post("/admin/setCookie", data, { withCredentials: true });
            console.log("Cookie set response:", response.data);
            return response.data;
        } catch (error) {
            console.error("Error setting cookie:", error.response?.data || error.message);
            throw error;
        }
    },
};

export default adminServices;