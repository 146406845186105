import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./AdminLayout.module.css";
import logo from "../../Assets/logo.png";
import folder from "../../Assets/folder.svg";
import submit from "../../Assets/submit.svg";
import mail from "../../Assets/mail.svg";
import settings from "../../Assets/settings.svg";
import Projects from "./Projects/Projects";
import AddProject from "./AddProject/AddProject";
import EditProject from "./EditProject/EditProject";
import FacebookFetch from "./FacebookFetch/FacebookFetch";
import Submissions from "./Submissions/Submissions";
import Mailings from "./Mailings/Mailings";
import Settings from "./Settings/Settings";
import adminFolder from "../../Assets/adminFolder.svg";
import adminSend from "../../Assets/adminSend.svg";
import adminMail from "../../Assets/adminMail.svg";
import adminSettings from "../../Assets/adminSettings.svg";
import adminLogout from "../../Assets/adminLogout.svg";
import exceljs from "exceljs";
import joinServices from "../../API/joinServices";
import mailingServices from "../../API/mailingServices";
import { Helmet } from "react-helmet";

export default function AdminLayout({ view }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    const [links, setLinks] = useState([]);
    const [activeTab, setActiveTab] = useState("members");
    const isMobile = window.innerWidth <= 768;

    const handleClearDataOnNavigation = () => {
        if (view === "New Project") {
            setDescription("");
            setLinks([]);
        }
    };

    const handleMobileNavigation = (path) => {
        navigate(path);
    };

    const handleExportMembers = async () => {
        try {
            const response = await joinServices.getAllRequests();
            const members = response.data.filter((item) => item.type === "member");

            const workbook = new exceljs.Workbook();
            const worksheet = workbook.addWorksheet("Members");

            worksheet.columns = [
                { header: "Full Name", key: "fullName", width: 30 },
                { header: "Organization Name", key: "organizationName", width: 30 },
                { header: "Organization Type", key: "organizationType", width: 30 },
                { header: "Email", key: "email", width: 30 },
                { header: "Phone Number", key: "phoneNumber", width: 20 },
                { header: "Reason for Joining", key: "reasonForJoining", width: 50 },
                { header: "Created At", key: "createdAt", width: 20 },
            ];

            members.forEach((member) => {
                worksheet.addRow({
                    fullName: member.fullName,
                    organizationName: member.organizationName,
                    organizationType: member.organizationType,
                    email: member.email,
                    phoneNumber: member.phoneNumber,
                    reasonForJoining: member.reasonForJoining,
                    createdAt: new Date(member.createdAt).toLocaleString(),
                });
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Members.xlsx";
            link.click();
        } catch (error) {
            console.error("Error exporting members:", error);
        }
    };

    const handleExportExperts = async () => {
        try {
            const response = await joinServices.getAllRequests();
            const experts = response.data.filter((item) => item.type === "expert");

            const workbook = new exceljs.Workbook();
            const worksheet = workbook.addWorksheet("Experts");

            worksheet.columns = [
                { header: "Full Name", key: "fullName", width: 30 },
                { header: "Email", key: "email", width: 30 },
                { header: "Phone Number", key: "phoneNumber", width: 20 },
                { header: "Areas of Expertise", key: "expertiseArea", width: 30 },
                { header: "Reason for Joining", key: "reasonForJoining", width: 50 },
                { header: "Created At", key: "createdAt", width: 20 },
            ];

            experts.forEach((expert) => {
                worksheet.addRow({
                    fullName: expert.fullName,
                    email: expert.email,
                    phoneNumber: expert.phoneNumber,
                    expertiseArea: expert.expertiseArea,
                    reasonForJoining: expert.reasonForJoining,
                    createdAt: new Date(expert.createdAt).toLocaleString(),
                });
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Experts.xlsx";
            link.click();
        } catch (error) {
            console.error("Error exporting experts:", error);
        }
    };

    const handleExportMailings = async () => {
        try {
            const response = await mailingServices.getHistory();
            const mailings = response.data.mailingHistory;

            const workbook = new exceljs.Workbook();
            const worksheet = workbook.addWorksheet("Mailings");

            worksheet.columns = [
                { header: "Name", key: "name", width: 30 },
                { header: "Email", key: "email", width: 30 },
                { header: "Phone", key: "phone", width: 20 },
                { header: "Preferred Time", key: "preferredTime", width: 20 },
                { header: "Coaching Type", key: "coachingType", width: 30 },
                { header: "Message", key: "message", width: 50 },
            ];

            mailings.forEach((mail) => {
                worksheet.addRow({
                    name: mail.name,
                    email: mail.email,
                    phone: mail.phone || "N/A",
                    preferredTime: mail.preferredTime,
                    coachingType: mail.coachingType,
                    message: mail.message,
                });
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "Mailings.xlsx";
            link.click();
        } catch (error) {
            console.error("Error exporting mailings:", error);
        }
    };

    const clearPreloadedData = () => {
        setDescription("");
        setLinks([]);
    };


    return (
        <div className={styles.admin}>
            <Helmet>
                <title>Admin - CBA</title>
            </Helmet>
            <ul className={styles.mobileNavigator}>
                <li
                    className={location.pathname === "/admin/projects" ? styles.selected : ""}
                    onClick={() => handleMobileNavigation("/admin/projects")}
                >
                    <img src={adminFolder} alt="Projects" />
                </li>
                <li
                    className={location.pathname === "/admin/submissions" ? styles.selected : ""}
                    onClick={() => handleMobileNavigation("/admin/submissions")}
                >
                    <img src={adminSend} alt="Submissions" />
                </li>
                <li
                    className={location.pathname === "/admin/mailing" ? styles.selected : ""}
                    onClick={() => handleMobileNavigation("/admin/mailing")}
                >
                    <img src={adminMail} alt="Mailing" />
                </li>
                <li
                    className={location.pathname === "/admin/settings" ? styles.selected : ""}
                    onClick={() => handleMobileNavigation("/admin/settings")}
                >
                    <img src={adminSettings} alt="Settings" />
                </li>
                <li onClick={() => handleMobileNavigation("/")}>
                    <img src={adminLogout} alt="Logout" />
                </li>
            </ul>
            <div className={styles.navigator}>
                <img src={logo} className={styles.logo} alt="Logo" />
                <nav className={styles.nav}>
                    <Link
                        className={`${styles.admin__nav__btn} ${
                            location.pathname === "/admin/projects" ? styles.active : ""
                        }`}
                        to="/admin/projects"
                        onClick={handleClearDataOnNavigation}
                    >
                        <img src={folder} alt="Projects" />
                        <p>Projects</p>
                    </Link>
                    <Link
                        className={`${styles.admin__nav__btn} ${
                            location.pathname === "/admin/submissions" ? styles.active : ""
                        }`}
                        to="/admin/submissions"
                        onClick={handleClearDataOnNavigation}
                    >
                        <img src={submit} alt="Submissions" />
                        <p>Submissions</p>
                    </Link>
                    <Link
                        className={`${styles.admin__nav__btn} ${
                            location.pathname === "/admin/mailing" ? styles.active : ""
                        }`}
                        to="/admin/mailing"
                        onClick={handleClearDataOnNavigation}
                    >
                        <img src={mail} alt="Mailing" />
                        <p>Mailing</p>
                    </Link>
                    <Link
                        className={`${styles.admin__nav__btn} ${
                            location.pathname === "/admin/settings" ? styles.active : ""
                        }`}
                        to="/admin/settings"
                        onClick={handleClearDataOnNavigation}
                    >
                        <img src={settings} alt="Settings" />
                        <p>Settings</p>
                    </Link>
                </nav>
            </div>
            <div className={styles.content}>
                <div className={styles.upper_nav}>
                    <h1>{view}</h1>
                    <div className={styles.action__btns}>
                        {view === "Projects" && (
                            <Link className={styles.add__btn} to="/admin/projects/new">
                                <button
                                    onClick={() => navigate("/admin/projects/new")}
                                    className="primary__btn"
                                >
                                    Add Project
                                </button>
                            </Link>
                        )}
                        {view === "New Project" && (
                            <button
                                onClick={() => navigate("/admin/projects/new/facebook")}
                                className="primary__btn"
                            >
                                Facebook Fetch
                            </button>
                        )}
                        {view === "Submissions" && isMobile && (
                            <div className={styles.btn__wrapper}>
                                {activeTab === "members" && (
                                    <button onClick={handleExportMembers} className="primary__btn">
                                        Export Members
                                    </button>
                                )}
                                {activeTab === "experts" && (
                                    <button onClick={handleExportExperts} className="primary__btn">
                                        Export Experts
                                    </button>
                                )}
                            </div>
                        )}
                        {view === "Submissions" && !isMobile && (
                            <div className={styles.btn__wrapper}>
                                <button onClick={handleExportMembers} className="primary__btn">
                                    Export Members
                                </button>
                                <button onClick={handleExportExperts} className="primary__btn">
                                    Export Experts
                                </button>
                            </div>
                        )}
                        {view === "Mailing" && (
                            <button onClick={handleExportMailings} className="primary__btn">
                                Export Mailings
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.view}>
                    {view === "Projects" && <Projects />}
                    {view === "New Project" && (
                        <AddProject
                            preloadedDescription={description}
                            preloadedLinks={links}
                            clearPreloadedData={clearPreloadedData}
                        />
                    )}
                    {view === "Facebook Fetch" && (
                        <FacebookFetch
                            setDescription={setDescription}
                            setLinks={setLinks}
                        />
                    )}
                    {view === "Edit Project" && <EditProject />}
                    {view === "Submissions" && (
                        <Submissions
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            isMobile={isMobile}
                        />
                    )}
                    {view === "Mailing" && <Mailings />}
                    {view === "Settings" && <Settings />}
                </div>
            </div>
        </div>
    );
}
