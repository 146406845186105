import http from './http';

const projectServices = {
    // Get all projects with pagination
    getProjects: (page) => http.get(`/projects?page=${page}`),

    // Get data for a single project
    getProject: (id) => http.get(`/projects/project/${id}`),

    // Get total pages for projects
    getPages: () => http.get('/projects/get-pages'),

    // Get temporary folder files
    getTempFolder: () => http.get('/projects/temp-folder'),

    // Upload files to the temporary folder
    uploadFile: (file) => {
        const formData = new FormData();
        formData.append('files', file);
        return http.post('/projects/upload-files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    // Remove a file from the temporary folder
    deleteFile: (fileName) => {
        return http.delete('/projects/remove-file', {
            data: { fileName },
        });
    },

    // Create a new project
    createProject: (data) => http.post('/projects/create-project', data),

    // Delete an existing project
    deleteProject: (id) => {
        return http.delete('/projects/delete-project', {
            data: { projectId: id },
        });
    },

    // Update an existing project
    updateProject: (projectId, data) => {
        return http.put(`/projects/update-project/${projectId}`, data);
    },

    // Upload files directly to a specific project folder
    uploadFileToProject: (projectId, formData) => {
        return http.post(`/projects/upload-files/${projectId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    // Delete a file from a specific project folder
    deleteFileFromProject: (projectId, fileName) => {
        return http.delete('/projects/delete-file-from-project', {
            data: { projectId, fileName },
        });
    },
};

export default projectServices;