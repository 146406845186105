import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import projectServices from "../../../API/projectServices";
import styles from "./AddProject.module.css";
import trash from "../../../Assets/trash.svg";
import upload from "../../../Assets/upload.svg";

export default function AddProject({ preloadedDescription = "", preloadedLinks = [], clearPreloadedData }) {
    const [heading, setHeading] = useState("");
    const [description, setDescription] = useState(preloadedDescription);
    const [links, setLinks] = useState(preloadedLinks);
    const [tempFiles, setTempFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const location = useLocation(); // Track navigation changes
    const navigate = useNavigate();

    useEffect(() => {
        setHeading(""); // Reset heading
        setDescription(preloadedDescription); // Reset description from props
        setLinks(preloadedLinks); // Reset links from props
        setTempFiles([]); // Clear uploaded files
    }, [preloadedDescription, preloadedLinks]); // Ensure this triggers on prop updates

    const handleLinkChange = (index, value) => {
        const updatedLinks = [...links];
        updatedLinks[index] = value;
        setLinks(updatedLinks);
    };

    const addLink = () => {
        setLinks([...links, ""]);
    };

    const removeLink = (index) => {
        const updatedLinks = links.filter((_, i) => i !== index);
        setLinks(updatedLinks);
    };

    const fetchTempFiles = async () => {
        try {
            const { data } = await projectServices.getTempFolder();
            setTempFiles(data.files || []);
        } catch (error) {
            console.error("Error fetching temp files:", error);
        }
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            setIsUploading(true);
            for (const file of files) {
                setCurrentFile(file.name);
                try {
                    await projectServices.uploadFile(file);
                    await fetchTempFiles();
                } catch (error) {
                    console.error(`Error uploading file ${file.name}:`, error);
                }
            }
            setIsUploading(false);
            setCurrentFile(null);
            e.target.value = "";
        }
    };

    const removeUploadedFile = async (fileName) => {
        try {
            await projectServices.deleteFile(fileName);
            await fetchTempFiles();
        } catch (error) {
            console.error("Error removing file:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const projectData = {
                heading,
                description,
                links,
            };
            await projectServices.createProject(projectData);
            alert("Project created successfully!");

            clearPreloadedData();

            navigate("/admin/projects");
        } catch (error) {
            console.error("Error creating project:", error);
            alert("Failed to create project.");
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchTempFiles();
    }, []);

    return (
        <div className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.form__group}>
                    <label htmlFor="heading">Heading</label>
                    <input
                        type="text"
                        id="heading"
                        name="heading"
                        placeholder="Write your heading"
                        value={heading}
                        onChange={(e) => setHeading(e.target.value)}
                        required
                    />
                </div>
                <div className={styles.form__group}>
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        placeholder="Write your description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    ></textarea>
                </div>
                <div className={styles.form__group}>
                    <div className={styles.label__row}>
                        <label>Links</label>
                        <button
                            type="button"
                            className={styles.add__link}
                            onClick={addLink}
                        >
                            Add Link
                        </button>
                    </div>
                    {links.map((link, index) => (
                        <div key={index} className={styles.link__wrapper}>
                            <input
                                type="url"
                                name={`link-${index}`}
                                placeholder="Enter link"
                                value={link}
                                onChange={(e) => handleLinkChange(index, e.target.value)}
                            />
                            <button
                                type="button"
                                className={styles.trash__btn}
                                onClick={() => removeLink(index)}
                            >
                                <img src={trash} alt="Delete" />
                            </button>
                        </div>
                    ))}
                </div>
                <button
                    type="submit"
                    className="primary__btn"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Submitting..." : "Create Project"}
                </button>
            </form>

            <div className={styles.right__section}>
                <div className={styles.file__upload}>
                    <label htmlFor="fileUpload" className={styles.upload__box}>
                        <div className={styles.upload__icon}>
                            <img src={upload} alt="Upload Icon" className={styles.upload__svg} />
                            {isUploading ? (
                                <span>Uploading: {currentFile}</span>
                            ) : (
                                <span>Click or drag files to upload</span>
                            )}
                        </div>
                        <input
                            type="file"
                            id="fileUpload"
                            className={styles.upload__input}
                            multiple
                            onChange={handleFileChange}
                        />
                    </label>
                </div>

                <div className={styles.uploaded__files}>
                    {tempFiles.map((file, index) => (
                        <div key={index} className={styles.file__item}>
                            <p>{file}</p>
                            <button
                                type="button"
                                className={styles.trash__btn}
                                onClick={() => removeUploadedFile(file)}
                            >
                                <img src={trash} alt="Remove file" />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
