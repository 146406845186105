import http from './http';

const mailingServices = {
    sendContactEmail: (data) => {
        return http.post('/mailing/send-contact-email', data);
    },
    getHistory: () => {
        return http.get('/mailing/history');
    }
}

export default mailingServices;