import styles from './Footer.module.css';
import logo from '../../Assets/logo.png';
import {Link, useNavigate} from "react-router-dom";
import facebook from '../../Assets/facebook.svg';
import phone from '../../Assets/phone.svg';
import email from '../../Assets/email.svg';

export default function Footer() {

    const navigate = useNavigate();

    const goToContact = () => {
        // Check if already on the landing page
        if (window.location.pathname === '/') {
            const contactSection = document.getElementById('contact');
            if (contactSection) {
                const offset = -100;
                const topPosition = contactSection.getBoundingClientRect().top + window.pageYOffset + offset;

                // Smoothly scroll to the position with offset
                window.scrollTo({
                    top: topPosition,
                    behavior: 'smooth',
                });
            }
        } else {
            // Set the flag only if navigating away from other pages
            localStorage.setItem('navigateToContact', true);
            navigate('/');
        }
    };

    return (
        <footer>
            <div className={styles.footer}>
                <img className={styles.logo} src={logo}/>
                <ul className={styles.links__grid}>
                    <li><Link className={styles.footer__link} to="/">Home</Link></li>
                    <li><Link className={styles.footer__link} to="/services">Our Services</Link></li>
                    <li><Link className={styles.footer__link} to="/about">About us</Link></li>
                    <li><Link className={styles.footer__link} to="/projects">Projects</Link></li>
                    <li>
                        <button onClick={goToContact} className={`${styles.footer__link} noBorderBtn`}>Contact</button>
                    </li>
                    <li><Link className={styles.footer__link} to="/privacy">Privacy Policy</Link></li>
                </ul>
                <div>
                    <p className={styles.socials}>Socials:</p>
                    <div className={styles.socials__wrapper}><img src={facebook} /><p>Facebook: <a
                        href="https://www.facebook.com/CoachingAssociationBG" target="_blank" rel="noopener noreferrer">Coaching
                        Association</a>
                    </p></div>
                </div>
            </div>
            <div></div>
        </footer>
    )
}