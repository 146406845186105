import styles from './Privacy.module.css';
import {Helmet} from "react-helmet";
import React from "react";

export default function Privacy() {
    return (
        <div className={styles.privacyContainer}>
            <Helmet>
                <title>Privacy - CBA</title>
            </Helmet>
            <h1 className={styles.privacyTitle}>Privacy Policy</h1>

            <section className={styles.privacySection}>
                <h2 className={styles.privacySectionTitle}>1. Information We Collect</h2>
                <p className={styles.privacyParagraph}>
                    We collect and process the following types of information:
                </p>
                <ul className={styles.privacyList}>
                    <li className={styles.privacyListItem}>
                        <strong>Personal Information:</strong> Includes name, email address, and contact details provided voluntarily through forms or during account registration.
                    </li>
                    <li className={styles.privacyListItem}>
                        <strong>Automatically Collected Information:</strong> Includes:
                        <ul className={styles.privacyList}>
                            <li className={styles.privacyListItem}>IP addresses to monitor traffic and ensure security.</li>
                            <li className={styles.privacyListItem}>
                                Data from Google Analytics, such as anonymized location, device information, and browsing behavior.
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>

            <section className={styles.privacySection}>
                <h2 className={styles.privacySectionTitle}>2. How We Use Your Information</h2>
                <p className={styles.privacyParagraph}>
                    The collected data is used for:
                </p>
                <ul className={styles.privacyList}>
                    <li className={styles.privacyListItem}>Improving website functionality and user experience.</li>
                    <li className={styles.privacyListItem}>Analyzing traffic patterns and user engagement.</li>
                    <li className={styles.privacyListItem}>Ensuring security by monitoring and filtering unauthorized access attempts.</li>
                    <li className={styles.privacyListItem}>Responding to inquiries and providing better support.</li>
                </ul>
            </section>

            <section className={styles.privacySection}>
                <h2 className={styles.privacySectionTitle}>3. Cookies and Tracking</h2>
                <p className={styles.privacyParagraph}>
                    We use cookies to enhance your experience by:
                </p>
                <ul className={styles.privacyList}>
                    <li className={styles.privacyListItem}>Storing preferences and session data for better usability.</li>
                    <li className={styles.privacyListItem}>Tracking user interactions through Google Analytics.</li>
                </ul>
                <p className={styles.privacyParagraph}>
                    You can manage your cookie preferences through browser settings or opt out of tracking by using the<br />
                    <a
                        href="https://tools.google.com/dlpage/gaoptout"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.privacyLink}
                    >
                        Google Analytics Opt-out Browser Add-on
                    </a>.
                </p>
            </section>

            <section className={styles.privacySection}>
                <h2 className={styles.privacySectionTitle}>4. Data Sharing</h2>
                <p className={styles.privacyParagraph}>
                    We do not sell or share your personal data with third parties, except in the following cases:
                </p>
                <ul className={styles.privacyList}>
                    <li className={styles.privacyListItem}>
                        <strong>Google Analytics:</strong> Google processes anonymized data as part of its services.
                        Refer to their <br />
                        <a
                            href="https://policies.google.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.privacyLink}
                        >
                            Privacy Policy
                        </a>.
                    </li>
                    <li className={styles.privacyListItem}>
                        <strong>Legal Compliance:</strong> Data may be disclosed when required by law or to protect
                        rights, property, or safety.
                    </li>
                </ul>
            </section>

            <section className={styles.privacySection}>
                <h2 className={styles.privacySectionTitle}>5. Data Retention</h2>
                <p className={styles.privacyParagraph}>
                    We retain:
                </p>
                <ul className={styles.privacyList}>
                    <li className={styles.privacyListItem}>
                        Google Analytics data indefinitely for statistical purposes.
                    </li>
                    <li className={styles.privacyListItem}>
                        Security logs and IP addresses for up to 24 hours, unless required
                        longer by law.
                    </li>
                </ul>
            </section>

            <section className={styles.privacySection}>
                <h2 className={styles.privacySectionTitle}>6. Your Rights</h2>
                <p className={styles.privacyParagraph}>
                    You have the right to:
                </p>
                <ul className={styles.privacyList}>
                    <li className={styles.privacyListItem}>
                        Access the data we hold about you.
                    </li>
                    <li className={styles.privacyListItem}>
                        Request corrections to inaccurate information.
                    </li>
                    <li className={styles.privacyListItem}>
                        Delete your personal data where applicable.
                    </li>
                    <li className={styles.privacyListItem}>
                        Opt-out of analytics tracking.
                    </li>
                </ul>
                <p className={styles.privacyParagraph}>
                    To exercise these rights, please contact us at svetlina666@gmail.com.
                </p>
            </section>

            <section className={styles.privacySection}>
                <h2 className={styles.privacySectionTitle}>7. Updates to This Policy</h2>
                <p className={styles.privacyParagraph}>
                    We may update this Privacy Policy periodically. Changes will be posted on this page with an updated
                    effective date. Your continued use of the website after any changes indicates acceptance.
                </p>
            </section>

            <section className={styles.privacySection}>
                <h2 className={styles.privacySectionTitle}>8. Contact Us</h2>
                <address className={styles.privacyAddress}>
                    Email: <a href="mailto:svetlina666@gmail.com" className={styles.privacyLink}>svetlina666@gmail.com</a> <br />
                    Phone: 089 487 0766<br />
                </address>
            </section>
        </div>
    );
}
