import axios from "axios";

async function fetchIPAddress() {
    try {
        const response = await axios.get('https://api.ipify.org?format=json');
        return response.data.ip;
    } catch (error) {
        console.error('Failed to fetch IP address:', error);
        return null;
    }
}

const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api` || "http://localhost:3001/api",
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true, // Ensures cookies are sent with requests
});

http.interceptors.request.use(
    async (config) => {
        const ipAddress = await fetchIPAddress();
        if (ipAddress) {
            config.headers['X-Client-IP'] = ipAddress;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error);
    }
);

export default http;