import React from "react";
import styles from './Login.module.css';
import background__img from '../../Assets/login.jpeg';
import { useAuth } from '../../Auth/AuthContext';
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";

export default function Login() {
    const { login } = useAuth();

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        const username = formData.get("username");
        const password = formData.get("password");

        try {
            await login(username, password);
            navigate("/admin");
        } catch (error) {
            alert("Login failed. Please check your username and password.");
        }
    };

    return (
        <div className={styles.login}>
            <Helmet>
                <title>Admin Login - CBA</title>
            </Helmet>
            <div className={styles.image__wrapper}>
                <img src={background__img} className={styles.background__img} alt="Background" />
            </div>
            <h1 className={styles.heading}>LOGIN</h1>
            <div className={styles.login__container}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formGroup}>
                        <label htmlFor="username" className={styles.label}>User</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Write your username here"
                            className={styles.input}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="password" className={styles.label}>Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Write your password here"
                            className={styles.input}
                            required
                        />
                    </div>
                    <button type="submit" className={`primary__btn ${styles.btn__fixer}`}>Login</button>
                </form>
            </div>
            <p className={styles.credits}>Powered by Carica Web</p>
        </div>
    );
}
