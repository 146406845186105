import { useEffect, useState } from 'react';
import styles from './Projects.module.css';
import projectServices from "../../../API/projectServices";
import pen from '../../../Assets/pen.svg';
import trash from '../../../Assets/trash.svg';
import {useNavigate} from "react-router";

export default function Projects() {
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const navigate = useNavigate();

    const fetchProjects = async (page) => {
        try {
            const response = await projectServices.getProjects(page);
            console.log('Fetched Projects:', response.data.projects);
            setProjects(response.data.projects);
        } catch (error) {
            console.error('Error fetching projects:', error.message);
        }
    };

    const fetchTotalPages = async () => {
        try {
            const response = await projectServices.getPages();
            setTotalPages(response.data.pages); // Update total pages from the response
        } catch (error) {
            console.error('Error fetching total pages:', error.message);
        }
    };

    const handleDeleteProject = async (id) => {
        try {
            await projectServices.deleteProject(id);
            alert('Project deleted successfully.');
            fetchProjects(currentPage); // Refetch projects for the current page
        } catch (error) {
            console.error('Error deleting project:', error.message);
            alert('Failed to delete the project.');
        }
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    useEffect(() => {
        fetchProjects(currentPage);
        fetchTotalPages();
    }, [currentPage]);

    return (
        <div>
            <ul className={styles.projects}>
                {projects.map(project => (
                    <li className={styles.project} key={project.id}>
                        <h4>{project.heading}</h4>
                        <p>{project.description}</p>
                        <div className={styles.btns__wrapper}>
                            <button
                                onClick={() => {
                                    navigate(`/admin/projects/edit/${project.id}`);
                                }}
                                className={styles.action__btn}><img src={pen} alt="Edit"/></button>
                            <button
                                className={styles.action__btn}
                                onClick={() => handleDeleteProject(project.id)}
                            >
                                <img src={trash} alt="Delete"/>
                            </button>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Pagination Controls */}
            <div className={styles.pagination}>
                <button
                    className='primary__btn'
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                    className='primary__btn'
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
}
