import http from './http';

const joinServices = {
    submitJoinRequest: (data) => {
        return http.post('/join', data);
    },
    getAllRequests: (data) => {
        return http.get('/join/get-all-join', data);
    }
}

export default joinServices;