import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Core/Header/Header';
import Landing from "./Features/Landing/Landing";
import About from "./Features/About/About";
import AdminLayout from "./Features/Admin/AdminLayout";
import ProtectedRoute from "./Auth/ProtectedRoute";
import { AuthProvider } from "./Auth/AuthContext";
import Login from "./Features/Login/Login";
import Projects from "./Features/Projects/Projects";
import SelectedProject from "./Features/Projects/SelectedProject/SelectedProject";
import Services from "./Features/Services/Services";
import Join from "./Features/Join/Join";
import Footer from "./Core/Footer/Footer";
import Privacy from "./Features/Privacy/Privacy";
import NotFound from "./Features/404/404";
import Maintenance from "./Features/Maintenance/Maintenance";
import adminServices from './API/adminServices';
import Cookies from "./Core/Cookies/Cookies";

// Conditional Header Component
function ConditionalHeader() {
    const location = useLocation();
    const hideHeaderOnRoutes = [
        "/login",
        "/admin",
        "/admin/projects",
        "/admin/submissions",
        "/admin/mailing",
        "/admin/settings",
        "/admin/projects/new",
        "/admin/projects/edit/:id",
        "/admin/projects/new/facebook",
    ];

    const isHeaderHidden = hideHeaderOnRoutes.some(route => location.pathname === route);
    const isEditProjectRoute = location.pathname.startsWith("/admin/projects/edit");

    if (isHeaderHidden || isEditProjectRoute) {
        return null;
    }

    return <Header />;
}

// Conditional Footer Component
function ConditionalFooter() {
    const location = useLocation();
    const hideFooterOnRoutes = [
        "/login",
        "/admin",
        "/admin/projects",
        "/admin/submissions",
        "/admin/mailing",
        "/admin/settings",
        "/admin/projects/new",
        "/admin/projects/edit/:id",
        "/admin/projects/new/facebook",
    ];

    const isFooterHidden = hideFooterOnRoutes.some(route => location.pathname === route);
    const isEditProjectRoute = location.pathname.startsWith("/admin/projects/edit");

    if (isFooterHidden || isEditProjectRoute) {
        return null;
    }

    return <Footer />;
}

function AppContent() {
    const [isMaintenance, setIsMaintenance] = useState(false);

    useEffect(() => {
        const fetchMaintenanceStatus = async () => {
            try {
                const response = await adminServices.getMaintenanceMode();
                setIsMaintenance(response.data.maintenance);
            } catch (error) {
                console.error("Error fetching maintenance mode:", error);
            }
        };

        fetchMaintenanceStatus();
    }, []);

    // Maintenance mode should not apply to admin routes
    const location = useLocation();
    const isAdminRoute = location.pathname.startsWith('/admin');
    const isLoginRoute = location.pathname === '/login';

    if (isMaintenance && !isAdminRoute && !isLoginRoute) {
        return <Maintenance />;
    }

    return (
        <>
            <ConditionalHeader />
            <div className="body__height">
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/project/:id" element={<SelectedProject />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/join/expert" element={<Join view="expert" />} />
                    <Route path="/join/member" element={<Join view="member" />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="*" element={<NotFound />} />
                    <Route
                        path="/admin"
                        element={
                            <ProtectedRoute>
                                <AdminLayout />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/projects"
                        element={
                            <ProtectedRoute>
                                <AdminLayout view="Projects" />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/submissions"
                        element={
                            <ProtectedRoute>
                                <AdminLayout view="Submissions" />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/mailing"
                        element={
                            <ProtectedRoute>
                                <AdminLayout view="Mailing" />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/settings"
                        element={
                            <ProtectedRoute>
                                <AdminLayout view="Settings" />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/projects/new"
                        element={
                            <ProtectedRoute>
                                <AdminLayout view="New Project" />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/projects/edit/:id"
                        element={
                            <ProtectedRoute>
                                <AdminLayout view="Edit Project" />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/projects/new/facebook"
                        element={
                            <ProtectedRoute>
                                <AdminLayout view="Facebook Fetch" />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </div>
            <ConditionalFooter />
            <Cookies />
        </>
    );
}

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <AppContent />
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
