import React, { useState } from "react";
import styles from "./Join.module.css";
import joinServices from "../../API/joinServices";
import { Helmet } from "react-helmet";
import join__1 from "../../Assets/join_member_1.svg";
import join__2 from "../../Assets/join_member_2.svg";
import join__3 from "../../Assets/join_member_3.svg";
import join__4 from "../../Assets/join_member_4.svg";
import join__5 from "../../Assets/join_member_5.svg";

export default function Join({ view }) {
    const [formData, setFormData] = useState({
        organizationName: "",
        fullName: "",
        email: "",
        phone: "",
        organizationType: "",
        reason: "",
        expertiseArea: "",
    });

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);

    const isExpert = view === "expert";

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setStatus(null);

        try {
            const response = await joinServices.submitJoinRequest({
                ...formData,
                type: isExpert ? "expert" : "member",
                phoneNumber: formData.phone,
                reasonForJoining: formData.reason,
            });

            setStatus({
                success: true,
                message: response.data.message || "Registration successful!",
            });

            setFormData({
                organizationName: "",
                fullName: "",
                email: "",
                phone: "",
                organizationType: "",
                reason: "",
                expertiseArea: "",
            });

            alert(
                "We will contact you by e-mail for further details on your application! Thank you for your time and consideration!"
            );
        } catch (error) {
            const errorMessage =
                error.response?.data?.error || "An unexpected error occurred. Please try again.";
            setStatus({ success: false, message: errorMessage });
            alert(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.join}>
            <Helmet>
                <title>Join CBA</title>
            </Helmet>
            <h2>{isExpert ? "Join as an Expert" : "Join as a Member"}</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.form__grid}>
                    {!isExpert && (
                        <div className={styles.form__group}>
                            <label htmlFor="organizationName">Organization Name</label>
                            <div className={styles.input__wrapper}>
                                <input
                                    type="text"
                                    id="organizationName"
                                    name="organizationName"
                                    value={formData.organizationName}
                                    onChange={handleChange}
                                    placeholder="ABC Corp."
                                    required
                                />
                                <img src={join__1} alt="Organization Icon" className={styles.input__icon} />
                            </div>
                        </div>
                    )}
                    <div className={styles.form__group}>
                        <label htmlFor="fullName">Full Name</label>
                        <div className={styles.input__wrapper}>
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                placeholder="John Doe"
                                required
                            />
                            <img src={join__2} alt="Full Name Icon" className={styles.input__icon} />
                        </div>
                    </div>
                    <div className={styles.form__group}>
                        <label htmlFor="email">Email</label>
                        <div className={styles.input__wrapper}>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="contact@example.com"
                                required
                            />
                            <img src={join__3} alt="Email Icon" className={styles.input__icon} />
                        </div>
                    </div>
                    <div className={styles.form__group}>
                        <label htmlFor="phone">Phone Number</label>
                        <div className={styles.input__wrapper}>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="+359 123 456 789"
                                required
                            />
                            <img src={join__4} alt="Phone Icon" className={styles.input__icon} />
                        </div>
                    </div>
                    {isExpert && (
                        <div className={styles.form__group}>
                            <label htmlFor="expertiseArea">Areas of Expertise</label>
                            <div className={styles.input__wrapper}>
                                <select
                                    id="expertiseArea"
                                    name="expertiseArea"
                                    value={formData.expertiseArea}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    <option value="personal-development">Personal Development Coaching</option>
                                    <option value="professional">Professional Coaching</option>
                                    <option value="performance-skills">Performance and Skills Coaching</option>
                                    <option value="educational">Educational Coaching</option>
                                    <option value="specialty">Specialty Coaching</option>
                                    <option value="corporate">Corporate Coaching</option>
                                    <option value="therapeutic">Therapeutic Coaching</option>
                                    <option value="diversity-inclusion">Diversity and Inclusion Coaching</option>
                                    <option value="special-populations">Special Populations Coaching</option>
                                    <option value="no-specific-expertise">No specific expertise</option>
                                </select>
                                <img src={join__4} alt="Arrow Icon" className={styles.dropdown__arrow} />
                            </div>
                        </div>
                    )}
                    {!isExpert && (
                        <div className={`${styles.form__group} ${styles.fullWidth}`}>
                            <label htmlFor="organizationType">Organization Type</label>
                            <div className={styles.input__wrapper}>
                                <select
                                    id="organizationType"
                                    name="organizationType"
                                    value={formData.organizationType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    <option value="educational-institution">Educational Institution</option>
                                    <option value="private-business">Private Business</option>
                                    <option value="non-profit">Non-Profit</option>
                                    <option value="government-body">Government Body</option>
                                    <option value="other">Other</option>
                                </select>
                                <img src={join__4} alt="Arrow Icon" className={styles.dropdown__arrow} />
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.form__group}>
                    <label htmlFor="reason">
                        {isExpert ? "Why do you want to join us?" : "Reason for joining us"}
                    </label>
                    <div className={styles.input__wrapper}>
                        <textarea
                            id="reason"
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            placeholder={
                                isExpert
                                    ? "Describe your motivation in 600 characters..."
                                    : "What are your goals in joining our association?"
                            }
                            maxLength="600"
                            required
                        ></textarea>
                        <img src={join__5} alt="Textarea Icon" className={`${styles.input__icon} ${styles.fixedIcon}`} />
                    </div>
                </div>

                <button type="submit" className="primary__btn" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                </button>

                {status && (
                    <p className={status.success ? styles.success : styles.error}>{status.message}</p>
                )}
            </form>
        </div>
    );
}
