import styles from './Landing.module.css'
import landing_1 from '../../Assets/landing_1.png'
import about_us from '../../Assets/about_us.png'
import our_mission from '../../Assets/our_mission.png'
import tree from '../../Assets/tree.svg'
import people_1 from '../../Assets/people_1.svg'
import people_2 from '../../Assets/people_2.svg'
import ball from '../../Assets/ball.svg'
import book_and_pen from '../../Assets/book_and_pen.svg'
import shaking_hands from '../../Assets/shaking_hands.svg'
import contact_icon from '../../Assets/contact_icon.svg'
import mail_icon from '../../Assets/mail_icon.svg'
import phone_icon from '../../Assets/phone_icon.svg'
import edit_icon from '../../Assets/edit_icon.svg'
import contactService from '../../API/mailingServices'
import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Landing() {

    const services = [
        {
            icon: tree,
            title: "Personal Development Coaching",
            description: (
                <p>
                    <span className='p-span'>Life Coaching:</span> Helps clients achieve personal goals, improve their relationships, and enhance day-to-day life.<br />
                    <span className='p-span'>Health and Wellness Coaching:</span> Focuses on improving health, dietary habits, physical activity, and overall wellness.<br />
                    <span className='p-span'>Spiritual Coaching:</span> Aids clients on their spiritual journey, often integrating philosophy, spirituality, and sometimes religion.
                </p>
            ),
        },
        {
            icon: people_1,
            title: "Professional Coaching",
            description: (
                <p>
                    <span className='p-span'>Executive Coaching:</span> Aimed at enhancing leadership, accountability, and performance of executives and high-potential employees.<br />
                    <span className='p-span'>Career Coaching:</span> Assists individuals in navigating career transitions, enhancing job performance, and achieving career goals.<br />
                    <span className='p-span'>Business Coaching:</span> Helps business owners and entrepreneurs grow their businesses, improve profitability, and manage business challenges.
                </p>
            ),
        },
        {
            icon: ball,
            title: "Performance and Skills Coaching",
            description: (
                <p>
                    <span className='p-span'>Life Coaching:</span> Helps clients achieve personal goals, improve their relationships, and enhance day-to-day life.<br />
                    <span className='p-span'>Health and Wellness Coaching:</span> Focuses on improving health, dietary habits, physical activity, and overall wellness.<br />
                    <span className='p-span'>Spiritual Coaching:</span> Aids clients on their spiritual journey, often integrating philosophy, spirituality, and sometimes religion.
                </p>
            ),
        },
        {
            icon: book_and_pen,
            title: "Educational Coaching",
            description: (
                <p>
                    <span className='p-span'>Academic Coaching:</span> Supports students in developing study habits, managing time, and achieving academic goals.<br />
                    <span className='p-span'>Test Preparation Coaching:</span> Prepares students for specific exams, such as SAT, ACT, GRE, GMAT, and more.<br />
                    <span className='p-span'>Language Coaching:</span> Specialized coaching to improve proficiency in a new language.
                </p>
            ),
        },
        {
            icon: people_2,
            title: "Specialty Coaching",
            description: (
                <p>
                    <span className='p-span'>Parenting Coaching:</span> Helps parents develop parenting skills, manage child-rearing challenges, and improve family dynamics.<br />
                    <span className='p-span'>Relationship Coaching:</span> Aids individuals or couples in fostering healthy relationships.<br />
                    <span className='p-span'>Financial Coaching:</span> Focuses on helping clients manage finances, including saving, investing, and debt management.
                </p>
            ),
        },
        {
            icon: shaking_hands,
            title: "Corporate Coaching",
            description: (
                <p>
                    <span className='p-span'>Team Coaching:</span> Aimed at improving the performance and dynamics of a team within a corporate environment.<br />
                    <span className='p-span'>Leadership Coaching:</span> Helps individuals develop leadership skills and manage teams effectively.<br />
                    <span className='p-span'>Organizational Development Coaching:</span> Works with organizations to improve time, systems, and performance.
                </p>
            ),
        },
    ];

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        preferredTime: "",
        help: "",
        coachingType: "",
    });

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("");
        setLoading(true);

        try {
            if (error) {
                setError(false);
            }

            const response = await contactService.sendContactEmail(formData);
            console.log("Form submitted successfully:", response);
            
            setStatus("Thank you for your enquiry! Our customer service will contact you in 3 business days!");
        } catch (error) {
            console.error("Error submitting form:", error);

            if (error.response?.data?.error) {
                const message = error.response.data.message || "An error occurred.";
                setStatus(`Error: ${message}`);
                setError(true);
            } else {
                setStatus("An error occurred. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('navigateToContact')) {
            const contactSection = document.getElementById('contact');
            if (contactSection) {
                const offset = -100;
                const topPosition = contactSection.getBoundingClientRect().top + window.pageYOffset + offset;

                // Smoothly scroll to the position with offset
                window.scrollTo({
                    top: topPosition,
                    behavior: 'smooth',
                });
            }
            // Remove the flag after scrolling
            localStorage.removeItem('navigateToContact');
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Coaching Bulgaria Association</title>
            </Helmet>
            <section className={styles.first__section}>
                <div className={styles.first__section__info}>
                    <h1>Coaching Bulgaria Association</h1>
                    <p>Since 2007, the Coaching Bulgaria Association has been at the forefront of <span
                        className='p-span'>enhancing coaching standards and practices as an independent, non-profit professional body.</span> We
                        are dedicated to fostering excellence and innovation in coaching, enriching both individual
                        lives and organizational success.
                    </p>
                    <div className={styles.action__btns__wrapper}>
                        <button onClick={() => {navigate('/join/member')}} className='primary__btn'>Join as a member</button>
                        <button onClick={() => {navigate('/join/expert')}} className='secondary__btn'>Join as an expert</button>
                    </div>
                </div>
                <img src={landing_1}/>
            </section>
            <section className={styles.services__section}>
                <h2>Our Services</h2>
                <div className={styles.services}>
                    {services.map((service, index) => (
                        <div key={index} className={styles.service}>
                            <img className={styles.service__icon} src={service.icon} alt={service.title}/>
                            <h3>{service.title}</h3>
                            {service.description}
                        </div>
                    ))}
                </div>
                <button onClick={() => {
                    scrollToTop();
                    setTimeout(() => {
                        navigate('/services')
                    }, 800)
                }} className='secondary__btn'>See all</button>
            </section>
            <section className={styles.about__us}>
                <img className={styles.about__us__img} src={about_us}/>
                <div className={styles.about__us__info}>
                    <h2>About us</h2>
                    <p>
                        Welcome to the Coaching Association - an independent, non-profit professional body founded in
                        2007, dedicated to elevating the practice and standards of coaching.<br/><br/>
                        We strive to promote excellence in coaching, advance the coaching profession, and make a
                        sustainable impact on individuals, organisations, and society at large.
                    </p>
                </div>
            </section>
            <section className={styles.our__mission}>
                <div className={styles.our__mission__info}>
                    <h2>Our Mission</h2>
                    <p>
                        <span className='p-span'>Our purpose</span> is multi-fold and deeply embedded in the belief that
                        quality coaching can profoundly enhance personal and professional lives. <span
                        className='p-span'>Our activities are designed to: </span><br/><br/>
                        <span className='p-span'>Develop and promote coaching practices:</span> we are committed to
                        developing cutting-edge coaching methodologies for different areas of life: personal,
                        professional, performance, educational, etc.<br/><br/>
                        <span className='p-span'>Training for Youth:</span> we believe that preparing young people for
                        their personal and professional success is essential for our communities. We develop specialised
                        and tailored coaching experience for young people. We also invite young people to join us in our
                        mission as volunteers.
                    </p>
                    <button onClick={() => {
                        scrollToTop();
                        setTimeout(() => {
                            navigate('/about')
                        }, 800)
                    }} className={`secondary__btn ${styles.mission__btn__fixer}`}>Learn More</button>
                </div>
                <img className={styles.our__mission__img} src={our_mission}/>
            </section>
            <section id='contact' className={styles.contact__section}>
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.form__grid}>
                        <div className={styles.form__group}>
                            <label htmlFor="fullName">Full Name</label>
                            <div className={styles.input__wrapper}>
                                <input
                                    type="text"
                                    id="fullName"
                                    name="name"
                                    placeholder="John Doe"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                <img
                                    src={contact_icon}
                                    alt="Contact Icon"
                                    className={styles.input__icon}
                                />
                            </div>
                        </div>
                        <div className={styles.form__group}>
                            <label htmlFor="email">Email Address</label>
                            <div className={styles.input__wrapper}>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="email@example.com"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <img
                                    src={mail_icon}
                                    alt="Mail Icon"
                                    className={styles.input__icon}
                                />
                            </div>
                        </div>
                        <div className={styles.form__group}>
                            <label htmlFor="phone">Phone Number</label>
                            <div className={styles.input__wrapper}>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    placeholder="+359 123 456 789"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <img
                                    src={phone_icon}
                                    alt="Phone Icon"
                                    className={styles.input__icon}
                                />
                            </div>
                        </div>
                        <div className={styles.form__group}>
                            <label htmlFor="preferredTime">Preferred Time for Contact</label>
                            <div className={styles.input__wrapper}>
                                <select
                                    id="preferredTime"
                                    name="preferredTime"
                                    value={formData.preferredTime}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    <option value="morning">Morning</option>
                                    <option value="afternoon">Afternoon</option>
                                    <option value="evening">Evening</option>
                                </select>
                                <span className={styles.dropdown__arrow}></span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.form__group}>
                        <label htmlFor="help">How Can We Help You?</label>
                        <div className={`${styles.input__wrapper} ${styles.textarea__wrapper}`}>
            <textarea
                id="help"
                name="help"
                placeholder="Please enter a brief description of your goals and/or any specific information or questions for us"
                value={formData.help}
                onChange={handleChange}
                className={styles.textarea}
                required
            ></textarea>
                            <img src={edit_icon} alt="Edit Icon" className={styles.input__icon}/>
                        </div>
                    </div>
                    <div className={styles.form__group}>
                        <label htmlFor="coachingType">
                            What Type of Coaching Are You Interested In?
                        </label>
                        <div className={styles.input__wrapper}>
                            <select
                                id="coachingType"
                                name="coachingType"
                                value={formData.coachingType}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>
                                    Select an option
                                </option>
                                <option value="personal-development">
                                    Personal Development Coaching
                                </option>
                                <option value="professional">Professional Coaching</option>
                                <option value="performance-skills">
                                    Performance and Skills Coaching
                                </option>
                                <option value="educational">Educational Coaching</option>
                                <option value="specialty">Specialty Coaching</option>
                                <option value="corporate">Corporate Coaching</option>
                                <option value="therapeutic">Therapeutic Coaching</option>
                                <option value="transition-change">
                                    Transition and Change Coaching
                                </option>
                                <option value="diversity-inclusion">
                                    Diversity and Inclusion Coaching
                                </option>
                                <option value="special-populations">
                                    Special Populations Coaching
                                </option>
                                <option value="other">Other</option>
                            </select>
                            <span className={styles.dropdown__arrow}></span>
                        </div>
                    </div>
                    <div className={styles.action__wrapper}>
                        {status && <p className={styles.success}>{status}</p>}
                        <button
                            type="submit"
                            className={`primary__btn ${styles.submit__btn__fixer}`}
                            disabled={loading}
                        >
                            {loading ? <div className="loader"></div> : "Submit"}
                        </button>
                    </div>
                </form>
            </section>
        </>
    )
}