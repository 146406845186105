import React, { useState, useEffect } from "react";
import styles from "./Settings.module.css";
import adminServices from "../../../API/adminServices";
import showPass from "../../../Assets/show-pass.svg";

export default function Settings() {
    const [isMaintenance, setIsMaintenance] = useState(false); // Tracks maintenance mode state.
    const [isLoading, setIsLoading] = useState(true); // Tracks if the maintenance state is being loaded.
    const [username, setUsername] = useState(""); // Username input state
    const [newPassword, setNewPassword] = useState(""); // New password state
    const [confirmPassword, setConfirmPassword] = useState(""); // Confirm password state

    useEffect(() => {
        const fetchMaintenanceState = async () => {
            try {
                const response = await adminServices.getMaintenanceMode();
                console.log("Fetched Maintenance Mode:", response); // Debug log
                if (response?.data?.maintenance !== undefined) {
                    setIsMaintenance(response.data.maintenance);
                } else {
                    console.error("Invalid structure in server response:", response);
                }
            } catch (error) {
                console.error("Error fetching maintenance mode state:", error.message);
            } finally {
                setIsLoading(false); // Mark as loaded
            }
        };

        fetchMaintenanceState();
    }, []);

    const handleToggleMaintenance = async () => {
        try {
            const response = await adminServices.toggleMaintenance();
            console.log("Maintenance mode toggled successfully:", response);
            if (response?.data?.maintenance !== undefined) {
                setIsMaintenance(response.data.maintenance);
            } else {
                console.error("Invalid structure in server response:", response);
            }
        } catch (error) {
            console.error("Error toggling maintenance mode:", error.message);
        }
    };

    const togglePasswordVisibility = (id) => {
        const input = document.getElementById(id);
        if (input) {
            input.type = input.type === "password" ? "text" : "password";
        }
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleChangeUser = async () => {
        if (!username) {
            alert("Please enter a username.");
            return;
        }
        try {
            console.log("Changing username to:", username);
            const response = await adminServices.changeUsername({ username });
            console.log("Username changed successfully:", response);
            alert("Username updated successfully.");
            setUsername(""); // Clear input
        } catch (error) {
            console.error("Error updating username:", error.message);
        }
    };

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }
        try {
            console.log("Changing password...");
            const response = await adminServices.changePassword({ newPassword });
            console.log("Password changed successfully:", response);
            alert("Password updated successfully.");
            setNewPassword(""); // Clear input
            setConfirmPassword(""); // Clear input
        } catch (error) {
            console.error("Error updating password:", error.message);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>; // Display a loader while fetching initial state
    }

    return (
        <div className={styles.container}>
            {/* Maintenance Mode */}
            <div className={styles.section}>
                <label className={styles.toggleLabel}>
                    <span>Maintenance mode</span>
                    <label className={styles.toggleSwitch}>
                        <input
                            type="checkbox"
                            checked={isMaintenance}
                            onChange={handleToggleMaintenance}
                        />
                        <span className={styles.slider}></span>
                    </label>
                </label>
            </div>

            {/* Change User Form */}
            <form className={styles.section} onSubmit={(e) => e.preventDefault()}>
                <label className={styles.label} htmlFor="changeUser">
                    Change User
                </label>
                <input
                    id="changeUser"
                    className={styles.input}
                    type="text"
                    value={username}
                    placeholder="Enter new username"
                    onChange={handleUsernameChange}
                />
                <button className="primary__btn" onClick={handleChangeUser}>
                    Save
                </button>
            </form>

            {/* Change Password Form */}
            <form className={styles.section} onSubmit={(e) => e.preventDefault()}>
                <label className={styles.label} htmlFor="newPassword">
                    New Password
                </label>
                <div className={styles.passwordWrapper}>
                    <input
                        id="newPassword"
                        className={styles.input}
                        type="password"
                        value={newPassword}
                        placeholder="Enter new password"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <button
                        type="button"
                        className={styles.togglePassword}
                        onClick={() => togglePasswordVisibility("newPassword")}
                    >
                        <img className={styles.show} src={showPass} alt="Toggle Password Visibility" />
                    </button>
                </div>

                <label className={styles.label} htmlFor="confirmPassword">
                    Confirm Password
                </label>
                <div className={styles.passwordWrapper}>
                    <input
                        id="confirmPassword"
                        className={styles.input}
                        type="password"
                        value={confirmPassword}
                        placeholder="Confirm new password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button
                        type="button"
                        className={styles.togglePassword}
                        onClick={() => togglePasswordVisibility("confirmPassword")}
                    >
                        <img className={styles.show} src={showPass} alt="Toggle Password Visibility" />
                    </button>
                </div>

                <button className="primary__btn" onClick={handleChangePassword}>
                    Save
                </button>
            </form>
        </div>
    );
}