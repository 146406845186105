import React, { createContext, useContext, useState, useEffect } from "react";
import adminServices from "../API/adminServices";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyAuth = async () => {
            try {
                const response = await adminServices.verify();
                setUser(response);
            } catch (error) {
                console.log("User not authenticated:", error.message);
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        verifyAuth();
    }, []);

    const login = async (username, password) => {
        try {
            const response = await adminServices.login(username, password);
            setUser({ username: response.username });
        } catch (error) {
            console.error("Login failed:", error.message);
            throw error;
        }
    };

    const logout = () => {
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);