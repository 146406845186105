import React, { useState, useEffect } from 'react';
import styles from "../Cookies/Cookies.module.css";
import cookie from '../../Assets/cookie.svg';

export default function Cookies() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookies');
        if (cookiesAccepted !== 'true') {
            setIsVisible(true);
        }
    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('cookies', 'true');
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.cookies}>
            <img src={cookie} className={styles.icon} alt="Cookie Icon" />
            <p>We use third-party cookies in order to personalize your site experience.</p>
            <button onClick={handleAcceptCookies} className={styles.cookieBtn}>Accept</button>
        </div>
    );
}