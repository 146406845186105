import styles from './Services.module.css';
import tree from "../../Assets/tree.svg";
import people_1 from "../../Assets/people_1.svg";
import ball from "../../Assets/ball.svg";
import book_and_pen from "../../Assets/book_and_pen.svg";
import people_2 from "../../Assets/people_2.svg";
import shaking_hands from "../../Assets/shaking_hands.svg";
import giving_hand from "../../Assets/giving_hand.svg";
import globe from '../../Assets/globe.svg';
import puzzle from '../../Assets/puzzle.svg';
import {Helmet} from "react-helmet";
import React from "react";

export default function Services() {
    const services = [
        {
            icon: tree,
            title: "Personal Development Coaching",
            description: (
                <p>
                    <span className='p-span'>Life Coaching:</span> Helps clients achieve personal goals, improve their relationships, and enhance day-to-day life.<br />
                    <span className='p-span'>Health and Wellness Coaching:</span> Focuses on improving health, dietary habits, physical activity, and overall wellness.<br />
                    <span className='p-span'>Spiritual Coaching:</span> Aids clients on their spiritual journey, often integrating philosophy, spirituality, and sometimes religion.
                </p>
            ),
        },
        {
            icon: people_1,
            title: "Professional Coaching",
            description: (
                <p>
                    <span className='p-span'>Executive Coaching:</span> Aimed at enhancing leadership, accountability, and performance of executives and high-potential employees.<br />
                    <span className='p-span'>Career Coaching:</span> Assists individuals in navigating career transitions, enhancing job performance, and achieving career goals.<br />
                    <span className='p-span'>Business Coaching:</span> Helps business owners and entrepreneurs grow their businesses, improve profitability, and manage business challenges.
                </p>
            ),
        },
        {
            icon: ball,
            title: "Performance and Skills Coaching",
            description: (
                <p>
                    <span className='p-span'>Skill Development Coaching:</span> Focused on enhancing specific skills like public speaking, time management, or technical expertise.<br />
                    <span className='p-span'>Athletic Coaching:</span> Guides athletes to improve their performance through mental and physical conditioning.<br />
                    <span className='p-span'>Artistic Coaching:</span> Supports artists in honing their craft and overcoming creative blocks.
                </p>
            ),
        },
        {
            icon: book_and_pen,
            title: "Educational Coaching",
            description: (
                <p>
                    <span className='p-span'>Academic Coaching:</span> Supports students in developing study habits, managing time, and achieving academic goals.<br />
                    <span className='p-span'>Test Preparation Coaching:</span> Prepares students for specific exams, such as SAT, ACT, GRE, GMAT, and more.<br />
                    <span className='p-span'>Language Coaching:</span> Specialized coaching to improve proficiency in a new language.
                </p>
            ),
        },
        {
            icon: people_2,
            title: "Specialty Coaching",
            description: (
                <p>
                    <span className='p-span'>Parenting Coaching:</span> Helps parents develop parenting skills, manage child-rearing challenges, and improve family dynamics.<br />
                    <span className='p-span'>Relationship Coaching:</span> Aids individuals or couples in fostering healthy relationships.<br />
                    <span className='p-span'>Financial Coaching:</span> Focuses on helping clients manage finances, including saving, investing, and debt management.
                </p>
            ),
        },
        {
            icon: shaking_hands,
            title: "Corporate Coaching",
            description: (
                <p>
                    <span className='p-span'>Team Coaching:</span> Aimed at improving the performance and dynamics of a team within a corporate environment.<br />
                    <span className='p-span'>Leadership Coaching:</span> Helps individuals develop leadership skills and manage teams effectively.<br />
                    <span className='p-span'>Organizational Development Coaching:</span> Works with organizations to improve time, systems, and performance.
                </p>
            ),
        },
        // New coaching categories
        {
            icon: giving_hand,
            title: "Therapeutic Coaching",
            description: (
                <p>
                    <span className="p-span">Mental Health Coaching:</span> Supports clients in managing mental health issues such as depression, anxiety, and stress.<br />
                    <span className="p-span">Recovery Coaching:</span> Helps individuals recover from addictions or major illnesses, often complementing other treatment forms.<br />
                    <span className="p-span">Grief Coaching:</span> Assists individuals in coping with loss and complex grief reactions.
                </p>
            ),
        },
        {
            icon: globe,
            title: "Diversity and Inclusion Coaching",
            description: (
                <p>
                    <span className="p-span">Cultural Competency Coaching:</span> Helps professionals understand and integrate into different cultural contexts, enhancing interpersonal and business relationships.<br />
                    <span className="p-span">Inclusion Coaching:</span> Focused on creating environments that value and utilize individual differences within organizations.
                </p>
            ),
        },
        {
            icon: puzzle,
            title: "Special Populations Coaching",
            description: (
                <p>
                    <span className="p-span">Youth Coaching:</span> Tailored to help young people navigate their unique life challenges.<br />
                    <span className="p-span">Senior Coaching:</span> Aims at assisting older adults in managing health, psychological, and transitional issues specific to aging.
                </p>
            ),
        },
    ];

    return (
        <section className={styles.services__section}>
            <Helmet>
                <title>Services - CBA</title>
            </Helmet>
            <h2>Our Services</h2>
            <div className={styles.services}>
                {services.map((service, index) => (
                    <div key={index} className={styles.service}>
                        <img className={styles.service__icon} src={service.icon} alt={service.title}/>
                        <h3>{service.title}</h3>
                        {service.description}
                    </div>
                ))}
            </div>
        </section>
    )
}